.static-page{ margin:45px 0 50px 0;}



/*/////////////////////////////////////////////// about-page */
.content-about{margin: 0 0 60px 0;}
.content-about h2{font-size: 35px;}
.content-about p{font-size: 20px;line-height: 35px;}
.content-about img{width:100%;height: 350px;object-fit: cover;border-radius:10px;}


/*/////////////////////////////////////////////// help-page */





/*contact-help*/
.contact-help{margin: 0 0 50px 0;}
.contact-help ul{display:flex;flex-wrap:wrap;justify-content: space-between;align-items:center;gap: 25px;margin: 25px 0 0 0;}
.contact-help ul li{width: 48%;}
.contact-help ul li:last-child{margin:0;}
.contact-help ul li a{color:#4B4CFF;font-size: 27px;font-family:'ProximaNova-Semibold';display: flex;align-items: center;justify-content: start;background:#F1F1F1;border-radius: 10px;width: 100%;padding: 25px;margin: 0 0 15px 0;}
.contact-help ul li a:hover{color:#4B4CFF;}
.contact-help ul li small {display: block;font-size: 17px;color:#000;}
.contact-help ul li a i{font-size:30px;color: #F800C0;background: #fff;margin: 0 20px 0 0;border-radius:50%;display: flex;align-items: center;justify-content: center;width: 75px;height: 75px;}
.contact-help ul li a:hover i{color: #fff;background:linear-gradient(to right, #F800C0, #FE8A70)}

/*faq-help*/

.faq-help h2{ margin:0;}


.faq-help .accordion{    background: #F1F1F1;    border-radius: 10px;    padding:30px 50px 45px 50px;    margin: 30px 0 50px 0;}
.faq-help .btn-load {    margin: 30px 0 0 0;}


.faq-help .accordion-item .accordion-button::after {background: #fff !important;}




/*/////////////////////////////////////////////// policy-page */
.policy-page h3{color:#4B4CFF;margin:15px 0 10px 0;}















@media only screen and (max-width:960px){






}

/* About us alternating layout */
.about-page .content-about {
    margin: 0 0 60px 0;
}

.about-page .content-about:nth-child(odd) .row {
    display: flex;
    flex-direction: row;
}

.about-page .content-about:nth-child(even) .row {
    display: flex;
    flex-direction: row-reverse;
}

.about-page .content-about .col-sm-6 {
    flex: 1;
}

.about-page .content-about .col-sm-6 img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 10px;
}

.about-page .content-about .col-sm-6 h2 {
    font-size: 35px;
}

.about-page .content-about .col-sm-6 p {
    font-size: 20px;
    line-height: 35px;
}

@media only screen and (max-width: 960px) {
    .about-page .content-about .row {
        flex-direction: column;
    }
}