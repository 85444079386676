/*home page ////////////////////////////////////////////////////////////////////////////////////*/

/*.home-page header*/
.home-page header{position: absolute;left: 0;top: 0;z-index: 999;margin:0; border:0; background-color: transparent;}

.slider
.slider{margin:0; position:relative;}
.slider img{width:100%;}
.slider .swiper{border-radius:0;}
.slider .swiper img{height:100vh;object-fit: cover;}

/**/
.caption-slider{width: 100%;height: 80%;padding: 0 15%;position:absolute;top: 10%;bottom: 10%;left:0;z-index:99;display: flex;align-items: center;justify-content: center;flex-direction: column;}
.caption-slider h2{font-size:45px;line-height:45px;color:#3447ef;margin: 0 0 20px 0;text-align: center;text-shadow: 0 4px 3px #fff;}
.caption-slider h2 span{ display:block;}

/*fredo-fp*/
.fredo-fp{width:60%;background: rgba(255,255,255,0.5);box-shadow: 0 3px 5px 0 #888;border-radius: 10px;padding:20px 25px 25px 25px;margin: 0 auto;text-align: center;} 

/**/
.ai-fredo-fp {margin: 0 0 15px 0;}
.ai-fredo-fp img {border: 1px solid #003dca;width: 70px !important;height: 70px !important;border-radius: 50px;}

/**/
.fredo-fp iframe{width:100%;height:150px;border-radius:10px;margin: 0 0 10px 0;} 

/**/
.select-fredo-fp{display:flex;justify-content:end;align-items:center;flex-wrap: wrap;margin: 0;}
.select-fredo-fp{background: #DBDBFF;border: 1px solid #4B4CFF!important;border-radius: 10px !important;padding: 1px 10px 1px 1px !important;margin: 0;display: flex;}
.select-fredo-fp select{border: 0!important;background: none !important;padding: 10px 15px !important;margin: 0 !important;line-height: normal !important;font-size: 15px !important;color: #4B4CFF !important;}

/*video-fredo-fp*/
.video-fredo-fp{display: flex;align-items: center;justify-content: center;width:100%;height: auto;padding:15px;margin: 0 0 15px 0;background:#FFE9FA;border:1px solid #F800C0;border-radius: 10px;}
.video-fredo-fp button{width: 100%;position: relative;}
.video-fredo-fp button i{position: absolute;left: 0;top: 0;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-size: 50px;color: #000;}
.video-fredo-fp button:hover i{color: #ff0000;transform:scale(1.1);}

.video-fredo-fp ul{text-align: left;}
.video-fredo-fp ul li{font-size: 12px;color: #F800C0;margin: 0 0 5px 0;}

/*search-fp*/
.search-fp {width: auto;display: flex; flex-direction: column;justify-content: center; align-items: center; text-align: center;}

.flex-search-fp{width: 100%;display: flex;align-items: center;justify-content: space-between;}
.sb-search-fp{width: 100%;height:75px;margin:0;padding: 4px 10px 4px 25px;background: #fff;border-radius: 100px;border: 0;box-shadow: 0px 4px 14px 0px #0000001A;display: flex;align-items: center;justify-content: space-between;}

.search-fp form{width: 100%;height: 100%;display:flex;justify-content: space-between;align-items:center;}
.search-fp input[type="text"]{max-width:80%;margin:0!important;padding:4px 10px 4px 20px !important;border:0!important;border-radius: 0 !important;background: none !important;font-size: 15px;color: #000;}

.search-fp button{background: #4B4CFF;width: 65px;height: 65px;border-radius: 50px;display: flex;align-items: center;justify-content: center;}
.search-fp button:hover{background:linear-gradient(to right, #F800C0, #FE8A70);}
.search-fp button img {width: 29px !important;margin: 0 0 0 3px;}

.search-fp ::-webkit-input-placeholder{color:#000}
.search-fp :-ms-input-placeholder{color:#000}
.search-fp ::-moz-placeholder{color:#000}
.search-fp :-moz-placeholder{color:#000}

/*btn-searchbox*/
.btn-searchbox{display: flex;align-items: center;justify-content: end;margin: 0 0 0 15px;}
.btn-searchbox a{display: flex;margin: 0 25px 0 0;}
.btn-searchbox a img{width: 28px!important;height: auto!important;transition: 0.5s;}

/**/
.ai-search-fp button{margin: 0 0 0 20px;}
.ai-search-fp button img {border: 1px solid #003dca;width: 80px !important;height: 80px !important;border-radius: 50px;}

/*home-page footer*/
.home-page footer{position: absolute;left: 0;bottom: 0;z-index: 9;margin:0;border:0;}
.home-page footer p{font-family: 'ProximaNova-Light';color: #fff;}

.home-page footer .smoicons ul li a{border: 1px solid #fff;}
.home-page footer .smoicons ul li a i{color:#fff;}

.home-page footer .menu-footer ul li a, .home-page footer .menu-footer ul li button{font-family: 'ProximaNova-Light';color: #fff;}
.home-page footer .menu-footer ul li a:hover, .home-page footer .menu-footer ul li button:hover{color:#fff; text-decoration:underline;}

/*end - home page ////////////////////////////////////////////////////////////////////////////////////*/




/*publisher-dashboard ////////////////////////////////////////////////////////////////////////////////////*/

.publisher-dashboard{ margin-top:15px;}

/*header*/
.header-publisher-db{border:0;}

/*btn-publisher-db*/
.btn-publisher-db{display: flex;align-items: center;justify-content: end;}
.btn-publisher-db p{background:#F800C0;cursor: text !important;border-radius: 50px;padding: 9px 15px; margin:0;color: #fff;font-size: 15px; line-height:normal;}
.btn-publisher-db .btn-primary{background:#4B4CFF !important;margin: 0 0 0 15px !important;}
.btn-publisher-db .btn-primary:hover {background: linear-gradient(to right, #F800C0, #FE8A70)!important;}

/*item-publisher-dashboard*/
.item-publisher-dashboard{background: #E6E6E6;box-shadow: 0px 4px 4px 0px #00000040;padding: 25px; margin:0 0 40px 0;border-radius: 15px;display: flex;align-items: center;justify-content: space-between;}  

.item-publisher-dashboard:nth-child(even){flex-direction: row-reverse;}  
.item-publisher-dashboard:last-child{ margin:0;}  
  
.item-publisher-dashboard div{ width:50%;}
.item-publisher-dashboard img{width: 100%;height: 250px;object-fit: cover;border-radius: 15px;box-shadow: 0px 4px 4px 0px #00000040;} 
.item-publisher-dashboard h3{width: 50%;display: flex;
align-items: center;justify-content: center;} 
.item-publisher-dashboard h3 a, .item-publisher-dashboard h3 button{color: #4B4CFF;font-size: 25px;padding:15px;}
.item-publisher-dashboard a:hover, .item-publisher-dashboard h3 button:hover{ color:#F800C0; text-decoration:underline !important;}
.item-publisher-dashboard button{width: 100%;border-radius: 16px;position: relative;}
.item-publisher-dashboard button i{position: absolute;left: 0;top: 0;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-size: 50px;color: #ff0000;}

/*footer*/
.footer-publisher-db{padding: 0 0 40px 0;text-align:center; border:0;}







/* attraction-home ////////////////////////////////////////////////////////////////////////////////////*/
.attraction-home{ margin-top:0 !important;}

/*banner-attraction-home*/
.banner-attraction-home {background-image: url(../../public/assets/images/attraction/search-attraction-home.jpg);background-position: center;background-size: cover;background-repeat: no-repeat;padding:110px 25px;margin: 0 0 50px 0;}
.banner-attraction-home h1{text-align:center;color:#fff;margin: 0 0 10px 0; font-size: 49px !important;}
.banner-attraction-home p{text-align:center; color:#fff; font-size:21px;margin: 0 0 30px 0;}

/**/
.banner-attraction-home form{display:flex;justify-content:center;align-items:center;height:65px;background: #fff;padding: 15px;border-radius: 50px;}
.field-bah{border-left: 1px solid #d9d9d9;padding: 0 20px;}
.field-bah:first-child{border-left:0;}
.field-bah label{font-size: 13px;color:#000;margin:0 0 5px 0; font-weight: bold;}
.field-bah select{font-size:12px!important; color:#888!important;border-radius:0!important;background:none!important;margin:0!important;padding:0!important;height:100%;}
.field-bah input[type="text"]{font-size: 13px!important;padding:0!important;margin:0!important;border:0!important;border-radius: 0!important;background:none!important;height:100%;}

/*heading*/
.heading-attraction-home{text-align:center;margin: 0 0 35px 0;}
.heading-attraction-home h2{ font-size:22px;position:relative;padding: 0 0 15px 0;}
.heading-attraction-home h2:after {content: '';width: 15%;height: 2px;background: #4b4cff;position: absolute;left: 42.5%;bottom: 0;}
.heading-attraction-home p{margin: 0;}

/*category-attraction*/
.category-attraction{margin: 0 0 60px 0;}
.item-category-attraction h3 {font-family: 'ProximaNova-Bold';font-size: 17px;margin: 20px 0 10px 0;}
.item-category-attraction h3 a{font-family: 'ProximaNova-Bold';}
.item-category-attraction p{margin:0;}

/*covered-attraction*/
.covered-attraction {background: #f7f8ff;padding: 60px 0;margin: 0 0 60px 0;}
.item-covered-attraction img {width: 100px;	margin:15px 0 20px 0;}
.item-covered-attraction {text-align: center;padding: 0 50px;}
.item-covered-attraction h3 {font-family: 'ProximaNova-Bold';font-size: 17px;}
.item-covered-attraction p {margin: 0;}

/*similar-attraction-home*/
.similar-attraction-home{ margin:0 0 60px 0;}
.similar-attraction-home h2{font-family: 'ProximaNova-Bold';font-size:20px; margin:0 0 20px 0;}
.similar-attraction-home .ib-attraction .review-ib{font-size: 12px !important;margin:4px 0 0 0;}

/*guide-attraction-home*/
.guide-attraction-home{margin: 0 0 60px 0;}
.guide-attraction-home h3 {color: #fff;font-size: 35px;}
.guide-attraction-home p {color: #fff;font-size: 18px;margin: 0 0 30px 0;}
.guide-attraction-home .imgeffect {height: 350px;}
.caption-guide-attraction-home {position: absolute;bottom: 0;left: 0;z-index: 999;display: flex;justify-content: center;align-items: start;flex-direction: column;background: rgba(0,0,0,0.6);width: 100%;height: 100%;padding: 40px;}
.guide-attraction-home .btn-primary {background: #fff !important;color: #000 !important;font-size: 20px!important;border-radius: 50px !important;padding: 12px 35px !important;}
.guide-attraction-home .btn-primary:hover {color: #fff!important;background: #f09276!important;border-color: #f09276!important;}

/*explore-destination*/
.explore-destination {padding:0 0 40px 0;}
.item-explore-destination {margin: 0 0 24px 0;position: relative;}
.item-explore-destination h3 {font-family: 'ProximaNova-Semibold';color: #fff;font-size: 17px;line-height: 20px;margin: 0 0 10px 0;}
.item-explore-destination h3 a{color: #fff;font-family: 'ProximaNova-Semibold';}
.item-explore-destination p {color: #fff;font-size: 13px;margin: 0;line-height: normal;}
.caption-explore-destination {position: absolute;bottom: 0;left: 0;z-index: 99;display: flex;justify-content: end;align-items: start;flex-direction: column;background: rgba(0,0,0,0.2);width: 100%;height: 100%;padding: 15px;}








/*///////////////////////////////////////// MYLIVE MORE /////////////////////////////////////////*/

.live-golive{border: 1px solid #e2e2e2;border-radius: 10px;padding:10px;margin: 0 0 50px 0;}

/*video-golive*/
.video-golive{ position:relative; margin:0 0 20px 0;}
.video-golive img{ width:100%;height: 365px;object-fit: cover;border-radius:10px;}

/*btn-share*/
.video-golive .btn-share { position:absolute; right:15px; top:15px; z-index:999;}
.video-golive .btn-share span{background: #f5f5f5;width: fit-content;height: 25px;margin: 0 0 0 8px;padding: 0 10px;display: flex;justify-content: center;align-items: center;border-radius: 50px;font-size: 12px;}
.video-golive .btn-share span i{background: none;width: auto;height: auto;margin: 0 5px 0 0;}

/*play-golive*/
.play-golive{position: absolute;left: 0;top: 0;z-index:99;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;}
.play-golive a{width: 50px;height: 50px;display: flex;align-items: center;justify-content: center;background: rgba(0,0,0,0.5);border:1px solid #fff;border-radius: 50%;}
.play-golive a:hover {background: #f09276;}
.play-golive i {font-size: 20px;color: #fff;}

.expand-golive{position: absolute;right: 15px;bottom:15px;z-index:999; width: 20px;height: 20px;display: flex;align-items: center;justify-content: center;background: rgba(0,0,0,0.5);border-radius: 50%;}
.expand-golive i {font-size:9px;color: #fff;}

/*profile-golive*/
.profile-golive {display: flex;align-items: center;justify-content: space-between;}
.profile-golive div{padding: 0 20px 0 0;}
.profile-golive img {width: 65px;height: 65px;border-radius: 50% ;margin: 0 15px 0 0;}
.profile-golive h3 {font-family: 'ProximaNova-Bold';font-size: 16px;margin: 0 0 5px 0;}
.profile-golive p{font-size: 12px;line-height: 18px;margin:0;}
.profile-golive .btn-primary{background: #003dca !important;border-radius: 5px !important;padding: 12px 10px !important;font-family: 'ProximaNova-Regular'!important;font-size: 12px !important;width: 245px;}
.profile-golive .btn-primary:hover{background: linear-gradient(to right, #F800C0, #FE8A70)!important;}

/*chatbox-golive*/
.chatbox-golive{}
.chat-golive{display: flex;align-items: center;justify-content: start;margin: 0 0 10px 0;}
.chat-golive img {width: 30px;height: 30px;border-radius: 50% ;margin: 0 25px 0 0;}
.chat-golive h4{ font-size:12px; margin:0 0 6px 0;}
.chat-golive h4 span{ position:relative; padding:0 0 0 20px;}
.chat-golive h4 span:before{content: '\f111';font-family: 'Font Awesome 6 Free';font-weight: bold;font-size: 4px;position: absolute; left: 6px;top: 7px;}
.chat-golive p{background:#f8f8f8;border-radius: 5px;padding: 7px 12px;font-family:'ProximaNova-Semibold';font-size: 13px;line-height: 17px;position:relative;display: flex;align-items: center;}
.chat-golive p:before{content: '';position: absolute;left: -12px;top: auto; bottom:auto;z-index: 9;border-bottom: 0 solid transparent;border-top: 12px solid transparent;
border-right: 12px solid #f8f8f8;}


/*field-chat-golive*/
.field-chat-golive{padding: 5px 7px 5px 10px;margin: 0;background:#fff;border:1px solid #ededed;border-radius: 10px;}
.field-chat-golive form{display:flex;justify-content:space-between;align-items:center;}

.field-chat-golive input[type="text"]{font-size: 14px;margin:0 !important;padding: 2px !important;border:0!important;}
.field-chat-golive button{display:flex;justify-content:center;align-items:center;background: #003dca;border:0 !important;padding: 0;margin: 0 0 0 15px;width:34px;height: 30px;outline:0;border-radius: 50%;transition:0.5s;}
.field-chat-golive button:hover{background:#f09276; color:#fff;}
.field-chat-golive button:focus{outline:none !important;}
.field-chat-golive button i{color: #fff;font-size: 14px;margin: 0;padding: 0;}

.field-chat-golive ::-webkit-input-placeholder{color:#655656}
.field-chat-golive :-ms-input-placeholder{color:#655656}
.field-chat-golive ::-moz-placeholder{color:#655656}
.field-chat-golive :-moz-placeholder{color:#655656}



/*prodcut-golive*/
.prodcut-golive{ margin:0 0 50px 0;}










@media only screen and (max-width:960px){

/*.home-page header*/
.home-page header {padding: 10px 0;position: static; }

/*slider*/
.slider .swiper img {height:550px;}
.caption-slider {height: auto;width: 100%;padding: 25px 0 12px 0;top: 0;bottom: 0;}
.caption-slider h2 {font-size: 25px;line-height: 30px;margin: 0 0 15px 0;text-shadow: 0 3px 2px #999;}	

/*fredo-fp*/
.fredo-fp {width: 100%;padding:15px;}	
.video-fredo-fp button i {font-size:40px;}

/*search-fp*/
.search-fp {flex-direction: column;}

.flex-search-fp {height: 60px;padding: 4px 10px 4px 20px;}

.search-fp input[type="text"] {padding: 4px 10px 4px 15px !important;font-size: 13px;}

.search-fp button {width: 40px;height: 40px;}
.search-fp button img {width: 20px !important;}

/**/
.ai-search-fp{ display:none;}
.ai-search-fp button{margin:25px 0 0 0;}
.ai-search-fp button img {width:60px !important;height:60px !important;}

/*.home-page footer*/
.home-page footer{position: static;margin: 0 !important; border: 0 !important;}
.home-page footer p{color: #000;}

.home-page footer .smoicons ul li a{border-color:#000;}
.home-page footer .smoicons ul li a i{color: #000;}

.home-page footer .menu-footer ul li a, .home-page footer .menu-footer ul li button{color: #000;}

/*publisher-dashboard ////////////////////////////////////////////////////////////////////////////////////*/

.publisher-dashboard{ margin-top:15px;}

/*header*/
.header-publisher-db{padding: 10px 0;}

/*btn-publisher-db*/
.btn-publisher-db{display: -webkit-box;overflow: auto;overflow-y: hidden;flex-wrap: nowrap;-ms-overflow-style: none;scrollbar-width: none;}
.btn-publisher-db p{background:#F800C0;cursor: text !important;border-radius: 50px;padding: 9px 15px; margin:0;color: #fff;font-size: 15px; line-height:normal;}
.btn-publisher-db .btn-primary{background:#4B4CFF !important;margin: 0 0 0 15px !important;}
.btn-publisher-db .btn-primary:hover {background: linear-gradient(to right, #F800C0, #FE8A70)!important;}

/*item-publisher-dashboard*/
.item-publisher-dashboard{padding: 15px;margin: 0 0 20px 0;flex-direction: column !important;}  

.item-publisher-dashboard:nth-child(even){flex-direction: row;}  
.item-publisher-dashboard:last-child{ margin:0;}  
  
.item-publisher-dashboard div{width: 100%;}
.item-publisher-dashboard img{width: 100%;height: 250px;object-fit: cover;border-radius: 15px;box-shadow: 0px 4px 4px 0px #00000040;} 
.item-publisher-dashboard h3{width: 100%;margin: 15px 0 0 0;} 
.item-publisher-dashboard h3 a, .item-publisher-dashboard h3 button{color: #4B4CFF;font-size: 25px;padding:15px;}
.item-publisher-dashboard a:hover, .item-publisher-dashboard h3 button:hover{ color:#F800C0; text-decoration:underline !important;}
.item-publisher-dashboard button{width: 100%;border-radius: 16px;position: relative;}
.item-publisher-dashboard button i{position: absolute;left: 0;top: 0;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;font-size: 50px;color: #ff0000;}

/*footer*/
.footer-publisher-db{padding: 15px 0 !important;border: 0 !important;}



/* attraction-home ////////////////////////////////////////////////////////////////////////////////////*/

/*banner-attraction-home*/
.banner-attraction-home {padding: 25px 15px;margin: 0 0 25px 0;}
.banner-attraction-home h1 {font-size: 17px;}
.banner-attraction-home p {font-size: 15px;margin: 0 0 20px 0;}

/*heading-attraction-home*/
.heading-attraction-home {margin: 0 0 20px 0;}
.heading-attraction-home h2 {font-size: 18px;padding: 0 0 10px 0;}
.heading-attraction-home p {padding: 10px 0 0 0;}

/*category-attraction*/
.category-attraction {padding: 0 0 25px 0;}
.item-category-attraction h3 {font-size: 16px;padding: 15px 0 5px 0;}

/*covered-attraction*/
.covered-attraction {padding: 25px 0;margin: 0 0 25px 0;}
.item-covered-attraction h3 {font-size: 15px;padding: 0 0 10px 0;}

/*similar-attraction-home*/
.similar-attraction-home {margin: 0 0 30px 0;}
.similar-attraction-home h2 {font-size: 18px;padding: 0 0 15px 0;}

/*guide-attraction-home*/
.guide-attraction-home-home{margin: 0 0 25px 0;}
.guide-attraction-home-home h3 {font-size: 18px;margin: 0 0 10px 0;}
.guide-attraction-home-home p {font-size: 15px;margin: 0 0 20px 0;}
.guide-attraction-home-home .imgeffect {height: 200px;}
.caption-guide-attraction-home-home {padding: 15px;}
.guide-attraction-home .btn-primary {padding: 5px 20px !important;}

/*explore-destination*/
.explore-destination {padding: 0 7px 10px 7px;}
.explore-destination .col-sm-3 {-ms-flex: 0 0 50% !important;flex: 0 0 50% !important;max-width: 50% !important;padding-right: 5px !important;padding-left: 5px !important;}
.item-explore-destination {margin: 0 0 10px 0;}
.item-explore-destination h3 {font-size: 14px;line-height: 18px;padding: 0 0 5px 0;}
.item-explore-destination p {font-size: 12px;}
}






















/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media only screen and (min-width:768px) and (max-width:960px){ 

}




/*large screens//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media only screen and (min-width : 1600px) { 

/*search-fp*/
.flex-search-fp{height: 99px;}
.ai-img img {border: 1px solid #5655FB; border-radius: 50%; width: 92px; background: #fff}

.search-fp input[type="text"]{padding: 10px 10px 10px 25px !important;font-size: 18px;}

.search-fp button{width: 70px;height: 70px;}
.search-fp button img {width: 30px !important;}

/**/
.ai-search-fp button{margin: 0 0 0 45px;}
.ai-search-fp button img {width: 99px !important;height: 99px !important;}


}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 10px;
}

::-webkit-scrollbar-button:vertical:increment {
    background-color: #fff;
}

::-webkit-scrollbar-track-piece {
    background-color: #eee;
    -webkit-border-radius: 3px;
}

::-webkit-scrollbar-thumb:vertical {
    height: 50px;
    background-color: #ccc;
    -webkit-border-radius: 3px;
}

::-webkit-scrollbar-thumb:horizontal {
    width: 50px;
    background-color: #ccc;
    -webkit-border-radius: 3px;
}

.z-index {
	z-index: 1000;
}