.video-signin {
  background-image: url(../../../public/assets/images/sign-bg.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.flex-signin {
  display: flex;
  align-items: stretch;
  /* Stretch elements to match heights */
  justify-content: start;
  min-height: 100vh;
  /* Make sure the container fills the viewport */
}
/* .react-tel-input .selected-flag {
  width: 70px !important;
} */


/* Add this to your global CSS file or as inline styles */

/* For the country code and flag container */
.phone-input-flag-button {
  background-color: #e0e0e0; /* Example background color */
  padding-left: 10px;        /* Add some padding if needed */
  padding-right: 10px;       /* Padding around flag */
  border-right: 1px solid #BFBFBF; /* Optional, to add separation between the flag and input */
}

/* Optional: To ensure the container respects the background color */
.phone-input-container input {
  padding-left: 50px !important; /* Adjust based on the width of the flag/country code section */
}


.placeholder-style::placeholder {
  
  font-size: 18px;   
  font-weight: 400;                /* Font Size */
  line-height: 38px;                 /* Line Height */
  color: #000000;                    /* Placeholder Color */
}
/* .react-tel-input .form-control{
  padding-left: 73px !important;
} */
.content-signin {
  background: #fff;
  width: 50%;
  height: 100%;
  padding: 25px 9% 45px 11%;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: flex-start;
  height: auto;
  min-height: 100vh;
  box-sizing: border-box;
}

/*logo-signin*/
.logo-signin {
  position: relative;
  margin: 0 0 40px 0;
}

.logo-signin img {
  width: 170px;
}

.logo-signin button {
  position: absolute;
  left: -80px;
  top: 13px;
}

.logo-signin button img {
  width: 44px;
}

/*tab signin*/
.tab-signin {
  padding: 0;
}

.tab-signin .tab-menu {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #ededed;
  width: 100%;
}

.tab-signin .tab-menu li {
  font-family: "ProximaNova-Bold";
  font-size: 20px;
  padding: 0;
  margin: 0 45px 0 0;
  color: #000;
  cursor: pointer;
  transition: 0.9s;
}

.tab-signin .tab-menu li:hover {
  color: #f800c0;
}

.tab-signin .tab-menu li.active {
  color: #f800c0;
  position: relative;
}

.tab-signin .tab-menu li.active:before {
  position: absolute;
  bottom: -16px;
  left: 0;
  z-index: 99;
  content: "";
  width: 100%;
  height: 2px;
  background: #4b4cff;
}

.tab-signin .tab-menu li.active:hover {
  color: #314252;
}

.tab-signin img {
  width: 21px;
  margin: 0 8px 0 0;
}

.tab-signin .tab-content {
  display: none;
  padding: 25px 0 0 0;
}

.tab-signin .tab-content.active {
  display: inherit !important;
}

.wel-signin {
  color: #000;
  font-size: 14px;
  line-height: 14px;
  margin: 0 0 20px 0;
}

.field-signin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border: 1px solid #bfbfbf;
  padding: 5px 15px;
  margin: 0 0 10px 0;
  border-radius: 5px;
  font-family: "ProximaNova-Bold";
}

.mobile {
  padding: 0;
}

select {
  font-size: 17px !important;
  font-family: 'ProximaNova-Regular' !important;
}

.sigin-up-select {
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  text-align: left;
  
}

.sigin-up-select [option]{
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  text-align: left;
  
}

.field-signin input[type="text"],
.field-signin input[type="email"],
.field-signin input[type="number"],
.field-signin input[type="date"],
.field-signin input[type="password"] {
  border: 0;
  background: none;
  margin: 0;
  padding: 5px 0 5px 15px;
  font-size: 15px;
 
}

.field-signin img {
  width: 18px;
  margin: 0 2px 0 0;
}

.field-signin i {
  font-size: 17px;
  color: #000;
  padding: 5px;
}

.forget-password {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 5px 0;
}

.forget-password div {
  display: flex;
  align-items: center;
  justify-content: start;
}

.forget-password label {
  font-family: "ProximaNova-Regular";
  font-size: 13px;
  color: #000;
  margin: 2px 0 0 8px;
}

.forget-password input[type="checkbox"] {
  margin: 0;
  padding: 0;
}

.forget-password a {
  font-family: "ProximaNova-Regular";
  font-size: 13px;
  color: #000;
  float: right;
}

.forget-password a:hover {
  color: #f09276;
}

.tab-signin input[type="submit"] {
  font-size: 18px;
  background: #065ad8;
  margin: 15px 0 0 0;
  padding: 15px;
  width: 100%;
  /* Ensure the button spans full width of the form */
  height: auto;
  /* Remove fixed height */
  border: none;
  text-align: center;
  border-radius: 5px;
  /* Add some border radius to match other elements */
  transition: background 0.3s ease;
}

.tab-signin input[type="submit"]:hover {
  background: #f09276;
}

.accept-signin {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 5px 0 0 0;
}

.accept-signin label {
  font-size: 13px;
  color: #000;
  margin: 1px 0 0 8px;
}

.accept-signin label a {
  color: #003dca;
}

.accept-signin input[type="checkbox"] {
  margin: 0;
  padding: 0;
}

.field-signin ::-webkit-input-placeholder {
  color: #000;
}

.field-signin :-ms-input-placeholder {
  color: #000;
}

.field-signin ::-moz-placeholder {
  color: #000;
}

.field-signin :-moz-placeholder {
  color: #000;
}

/*breaker*/
.breaker {
  position: relative;
  margin: 20px 0;
  font-family: "ProximaNova-Semibold";
  font-size: 15px;
  color: #000;
  text-align: center;
}

.breaker:before,
.breaker:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  background: #000;
  width: 22%;
  height: 1px;
}

.breaker:after {
  right: 0;
  left: auto;
}

/*social-login*/
.social-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-login a {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #bfbfbf;
  padding: 10px 15px;
  margin: 0 5px 0 0;
  border-radius: 5px;
  color: #030303;
  font-family: "ProximaNova-Semibold";
  font-size: 16px;
}

.social-login a:hover {
  color: #f09276;
  border-color: #f09276;
}

.fb {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background: #3b5998;
  border-radius: 50%;
  margin: 0 12px 0 0;
  color: #fff;
  font-size: 14px;
}

.social-login img {
  width: 20px;
  height: 20px;
  margin: 0 9px 0 0;
}

.apple {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 0 12px 0 0;
  font-size: 1.2rem;
}

.google {
  border: 1px solid #bfbfbf;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 0 12px 0 0;
  font-size: 1.2rem;
  height: 3rem;
  padding: 0 20px;
}

/*dont-account*/
.dont-account {
  font-family: "ProximaNova-Semibold";
  color: #000;
  text-align: center;
  font-size: 15px;
  margin: 25px 0 0 0;
}

.dont-account a {
  color: #f800c0;
  font-family: "ProximaNova-Bold";
}

.dont-account a:hover {
  color: #f09276;
}

/*face-login*/
.face-login {}

.content-face-login {
  background: #f4f4f4;
  border-radius: 10px;
  padding: 15px;
  margin: 0 0 20px 0;
  text-align: center;
}

.face-login h3 {
  color: #030303;
  font-size: 21px;
}

.face-login img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin: 0 0 12px 0;
}

.face-login p {
  font-family: "ProximaNova-Semibold";
  color: #030303;
  font-size: 15px;
  line-height: 21px;
  margin: 0;
}

.face-login ul {
  margin: 0 0 25px 0;
}

.face-login ul li {
  color: #818181;
  font-size: 12px;
  position: relative;
  padding: 0;
  margin: 0 0 10px 0;
}

.face-login ul li i {
  font-size: 11px;
  margin: 0 2px 0 0;
}

.face-login button {
  width: 100%;
  background: #065ad8;
  padding: 15px 15px;
  border-radius: 10px;
  color: #fff;
  font-size: 17px;
  font-family: "ProximaNova-Semibold";
}

.face-login button:hover {
  color: #fff;
  background: #f09276;
}

/*copyright*/
.copyright-signin {
  padding: 10px 0 0 0;
}

.copyright-signin p {
  font-size: 13px;
  line-height: normal;
  color: #818181;
  text-align: center;
  margin: 20px 0 0 0;
}

.copyright-signin p a {
  color: #818181;
  text-decoration: underline !important;
}

/*video-signin*/
.video-signin {
  background-image: url(../../../public/assets/images/sign-bg.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: auto;
  min-height: 100vh;
  padding: 25px 70px;
  box-sizing: border-box;
}

.video-signin button {
  width: 100%;
  background: #000;
  border-radius: 10px;
  position: relative;
}

.video-signin button i {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 90px;
  color: #fff;
}

.video-signin button:hover i {
  color: #ff0000;
  transform: scale(1.1);
}

.video-signin img {
  width: 100%;
  height: 325px;
  object-fit: cover;
  border-radius: 10px;
  opacity: 0.6;
}

/*//////////////////////////////////////// otp-verify*/
.otp-verify {
  height: 70vh;
  padding: 0;
}

.back-btn img {
  width: 45px;
}

.otp-verify h3 {
  margin: 35px 0 40px 0;
  font-family: "ProximaNova-Semibold";
  font-size: 18px;
  line-height: 25px;
}

.otp-verify h3 span {
  color: #f800c0;
}

.flex-otp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 0 0 30px 0;
}

.flex-otp input[type="text"] {
  width: 55px;
  height: 55px;
  margin: 0;
  background: #f5f5f5;
  border: 1px solid #000;
  padding: 5px;
  text-align: center;
  font-size: 20px;
  color: #000;
}

.resent-otp {
  text-align: right;
}

.resent-otp a {
  color: #f800c0;
}

.otp-verify input[type="submit"] {
  width: 100%;
  padding: 15px 20px;
  margin: 40px 0 0 0;
}

@media only screen and (max-width: 960px) {
  .flex-signin {
    flex-direction: column;
  }

  .content-signin {
    width: 100%;
    padding: 15px;
  }

  /*logo-signin*/
  .logo-signin {
    margin: 0 0 25px 0;
    border-bottom: 1px solid #ddd;
    padding: 0 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .logo-signin img {
    width: 140px;
  }

  .logo-signin button {
    position: static;
    margin: 0;
  }

  .logo-signin button i {
    font-size: 15px;
  }

  /*tab signin*/
  .tab-signin {
    padding: 0;
  }

  .tab-signin .tab-menu li {
    font-size: 18px;
    margin: 0 30px 0 0;
  }

  .tab-signin .tab-content {
    padding: 20px 0 0 0;
  }

  .field-signin {
    padding: 10px;
    margin: 0 0 8px 0;
  }

  .field-signin input[type="text"],
  .field-signin input[type="email"],
  .field-signin input[type="number"],
  .field-signin input[type="date"],
  .field-signin input[type="password"] {
    padding: 2px 0 2px 12px;
    font-size: 13px;
  }

  .field-signin i {
    font-size: 15px;
  }

  .forget-password {
    font-size: 12px;
    font-family: "Poppins-Regular";
  }

  .tab-signin input[type="submit"] {
    font-size: 15px;
    margin: 10px 0 0 0;
    padding: 12px;
  }

  /*social-login*/
  .social-login {
    justify-content: start;
  }

  .social-login a {
    padding: 10px;
    margin: 0 10px 10px 0;
    font-size: 14px;
  }

  .social-login i {
    width: 21px;
    height: 21px;
    font-size: 12px;
  }

  .social-login img {
    width: 18px;
    height: 18px;
    margin: 0 10px 0 0;
  }

  /**/
  .dont-account {
    margin: 15px 0 5px 0;
  }

  /*copyright*/
  .copyright-signin {
    padding: 7px 0 0 0;
  }

  .copyright-signin p {
    padding: 0 0 10px 0;
    text-align: left;
  }

  /*video-signin*/
  .video-signin {
    width: 100%;
    height: 100%;
    padding: 25px;
  }

  .video-signin button i {
    font-size: 50px;
  }

  .video-signin img {
    height: 200px;
  }

  /*//////////////////////////////////////// otp-verify*/
  .otp-verify {
    width: 100%;
    height: 100vh;
    padding: 20px;
  }

  .content-otp {
    width: 100%;
    padding: 20px;
  }

  .form-otp input[type="text"] {
    width: 40px;
    height: 40px;
    margin: 0 5px 0 0;
    padding: 2px;
    font-size: 15px;
  }
}

/* large screens //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media only screen and (min-width: 1600px) {
  .content-signin {
    height: 100vh;
    padding: 25px 9% 45px 11%;
  }
}

.input-mobile {
  margin: 0 0 0px 0 !important;
  border: none !important;
  font-family: 'ProximaNova-Regular';
  color: #000 !important;
  font-size: 15px !important;
}