.slider-container {
    padding: 30px 0 0;
  }
  
  .slider-main {
    position: relative;
    flex: 1;
    height: 430px;
    background-color: #0000ff;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-right: 20px;
  }
  
  .verified-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    color: blue;
    padding: 5px;
    border-radius: 5px;
  }
  
  .slider-content {
    font-size: 24px;
  }
  
  .prev-btn,
  .next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    height: 39px;
    border-radius: 50%;
    width: 39px;
  }
  
  .prev-btn {
    left: 10px;
  }
  
  .next-btn {
    right: 10px;
  }
  
  .slider-thumbnails {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .thumbnail {
    width: 190px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 12px;
    color: white;
    font-size: 16px;
    background-color: #0000ff;
  }
  
  .thumbnail.active {
    border: 2px solid #fff;
  }


.top-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0000ff;
  padding: 10px 20px;
  color: white;
  border-radius: 10px;
}

.left, .right {
  display: flex;
  align-items: center;
}

.verified {
  display: flex;
  align-items: center;
}

.verified-icon {
  background-color: white;
  color: #0000ff; /* Blue */
  border-radius: 50%;
  padding: 5px;
  margin-right: 10px;
}

.verified-text {
  font-size: 18px;
}

.right {
  gap: 20px;
}

/* .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #0000ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 18px;
  cursor: pointer;
} */

.icon.save {
  background-color: #ff00ff;
}

  