body {
    background-color: #F0F2F5;
}

.icon img {
    width: 40.49px;
    height: 38.19px;
    top: -75325.9px;
    left: -70479.34px;
    gap: 0px;
    opacity: 0px;

}

.card-title {
    color: #003DCA;
}

.card-text {
    color: #000000;
}

.card:hover {
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    transition: all 0.3s ease;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal is above other elements */
  }
  
  .modal-content {
    background: white;
    border-radius: 15px;
    padding: 20px;
    height: 90vh;
  }

  