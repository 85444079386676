
  /* Filter Buttons */
  .filter-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px 0;
  }
  .filter-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .filter-button {
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
  }
  
  .filter-button.active {
    background-color: #e0e0ff;
    border-color: #5c5cff;
  }
  
  .dropdown select {
    padding: 7px !important;
    border-radius: 25px !important;
    border: 1px solid #F800C0 !important;
    cursor: pointer;
    margin: 0 !important;
}
  
  .filter-btn {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
  }
  
  /* Content Layout */
  .content {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }
  
  .job-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 75%;
  }
  
  .job-card {
    background-color: white;
    border-radius: 15px;
    max-width: 300px;
    overflow: hidden;
    border: 1px solid #EDEDED;
}
  
  .job-image {
    position: relative;
    background-color: #ff00ff;
    padding: 20px;
    text-align: center;
    color: white;
  }
  
  .date-posted {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 12px;
    color: #fff;
  }
  
  .icon-container {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
  }
  
  .icon-star, .icon-heart {
    font-size: 16px;
    cursor: pointer;
  }
  
  .image-placeholder {
    background-color: #ff00ff;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
  }
  
  .job-details {
    border-top: 1px solid #f0f0f0;
  }

  .j-details {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.j-logo {
  width: 60px;
  height: 60px;
  word-wrap: break-word;
  font-size: 13px;
  border: 1px solid #fff;
  background: #003DCA;
  border-radius: 100%;
  outline: 1px solid red;
  line-height: 15px;
  color: #fff;
  min-width: 60px;
  margin-left: 10px;
  text-align: center;
  padding: 12px;
}
  
  .job-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }
  
  .company-name {
    color: #ff00ff;
    font-size: 14px;
    margin: 5px 0;
  }
  
  .job-description {
    font-size: 12px;
    color: #888;
  }
  
  .job-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ddd;
    padding: 10px;
}
  
  .info-item {
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .j-description {
    line-height: 16px;
}
  
  .icon {
    margin-right: 5px;
  }
  
  .apply-now {
    color: #ff00ff;
    font-weight: bold;
    cursor: pointer;
  }
  
  .logo-container {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .logo-placeholder {
    font-size: 10px;
    text-align: center;
    color: #4a4a4a;
  }
  
  
  /* Sidebar Styles */
  .sidebar {
    width: 23%;
  }
  
  .video-ad, .signup-ad {
    margin-bottom: 20px;
  }
  
  .video-placeholder {
    background-color: black;
    color: white;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    border-radius: 15px;
  }
  
  .signup-ad {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .register-btn {
    padding: 10px 20px;
    background-color: #ff00ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .categories-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0;
  }
  
  .category {
    padding: 5px 15px;
    border: 1px solid #D9D9D9;
    border-radius: 30px;
    font-size: 13px;
    color: #4a4a4a;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    background-color: #fff;
    cursor: pointer;
}
  
  .category:hover {
    background-color: #f0f0f5;
  }
  
  .count {
    color: #4B4CFF;
    margin-left: 5px;
    font-size: 12px;
}
  
  .category:last-child {
    color: #F800C0;
    border-color: #d9d9d9;
    font-size: 13px;
}
  
  